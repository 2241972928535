<template>
    <section class="appointment_list_wrp">
        <div class="container-fluid">
            <div class="appointment_list_header_wrp">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <h1 class="appointment_list_heading">
                            <router-link class="btn back_btn" to="/dashboard/">
                                <i aria-hidden="true" class="fa fa-chevron-left"></i>
                            </router-link> My Doctors
                        </h1>
                    </div>
                </div>
            </div>
            <div class="appointment_list_body_wrp">
                <table class="table" v-if="favApptmnListData">
                    <thead>
                        <tr>
                            <th width="30%" class="member_name">Name</th>
                            <th width="25%" class="member_country text-center">License Num.</th>
                            <th width="15%" class="member_country text-center">Country</th>
                            <!-- <th width="25%" class="member_organization text-center">Hospital <br> Organization</th> -->
                            <th width="30%" class="member_status text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="favApptmnListResults.length > 0">
                        <tr v-for="(item, index) in favApptmnListResults" :key="index">
                            <td class="m_u_info">
                                <span class="member_user_avtaar">
                                    <a href="javascript:void(0)">
                                        <img v-if="item.profile.thumbnail" :src="item.profile.thumbnail" alt=""
                                            width="50">
                                        <img src="@/assets/images/user2.png"
                                            v-bind:class="item.profile.thumbnail ? 'd-none' : ''" alt="" width="50">
                                    </a>
                                    <i class="online-status" v-bind:class="{ online: item.is_online }"></i>
                                </span>
                                <span class="member_user_info">
                                    <span class="member_user_name">
                                        <a href="javascript:void(0)">
                                            Dr {{ item.first_name }} {{ item.last_name }}
                                        </a>
                                    </span>
                                </span>
                            </td>
                            <td class="text-center">
                                <div class="dctr_slctd_cat">
                                    <span class="dctr_reg_num">Reg.#: {{item.license_value}}</span>
                                </div>
                            </td>
                            <td class="text-center m_u_country_flag">
                                <template v-if="item.profile.country">
                                    <img :src="getCountryFlag(item.profile.country, 'flag')" class="country-flag" />
                                </template>
                                <template v-else>
                                    <img :src="getCountryFlag('in', 'flag')" class="country-flag" />
                                </template>
                            </td>
                            <!-- <td class="m_u_area_of_work text-center">
                            <template v-if="item.profile.area_of_work"><span
                                class="member_hosiptal">{{item.profile.area_of_work}}</span></template>
                            <template v-else><span class="blank_val">-</span></template>
                        </td> -->
                            <td class="text-right">
                                <template v-if="item.appointment_allowed">
                                    <router-link class="btn btn-primary"
                                        :to="{ name: 'appointmentBook', params: { doctorID: item.user } }">Book
                                        Appointment</router-link>
                                </template>
                                <template v-else>
                                    <router-link class="btn btn-primary"
                                        :to="{ name: 'appointmentDetail', params: { appointmentId: item.user } }">
                                        Consultation Ongoing</router-link>
                                </template>
                                <!-- <button class="btn btn-primary msg_reply ml-2">
                                <i class="fa fa-commenting initial_message_btn" aria-hidden="true"
                                @click="beforeBookingMessageListMethod(item)" style="font-size:18px;"></i>
                            </button> -->
                                <button class="btn btn-primary ml-2 is_favourite"
                                    @click="unFavouriteDoctorMethod(item.user)">
                                    <i class="fa fa-heart" aria-hidden="true" style="font-size:18px;"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">
                                <div class="no_data_in_info_box_wrp">
                                    <h4>You don't have any doctors yet. Book your first appointment with a doctor of your choice to get your favourite doctors' list rolling!</h4>
                                    <router-link :to="{ name: 'doctorsList' }" class="btn btn-default">Book Your First
                                        Appointment
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="compontent-loader" v-else>
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        <unfavourite-doctor-list v-if="unFavDoctorPopupState" :doctorID="doctorId"></unfavourite-doctor-list>
    </section>
</template>
<script>
import {bus} from '@/main';
import countries from '@/services/country.json';
import makeUnfavouriteDoctor from '@/components/private/make-unfavourite-doctor';
export default {
    name: 'my-doctors-list',
    components: {
        'unfavourite-doctor-list' : makeUnfavouriteDoctor
    },
    data() {
        return {
            favApptmnListData: null,
            favApptmnListResults: [],
            directSmsForPopup: false,
            userInfo: null,
            countryList:[],
            doctorId:'',
            unFavDoctorPopupState:false
        }
    },
    mounted() {
        this.getFavDoctorsListMehtod();
        Object.keys(countries).map((key) => {
        this.countryList.push({
            name: countries[key],
            value: key.toLowerCase()
        })
        });
        this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
        bus.$on('unFavDoctorPopupStateBus', (data) => {
            this.getFavDoctorsListMehtod();
            this.unFavDoctorPopupState = data;
        });
    },
    methods: {
        unFavouriteDoctorMethod(id){
            document.body.classList.add('popup_open_body')
            this.doctorId = id;
            this.unFavDoctorPopupState = true
        },
        getFavDoctorsListMehtod() {
            this.$store.dispatch('getDoctorFavouriteListFun').then((res) => {
                console.log(res.data.data)
                this.favApptmnListData = res.data.data;
                this.favApptmnListResults = this.favApptmnListData.results || []
            })
        },
        directSmsForPopupOpenMethod(data) {
            this.userInfo = data
            this.directSmsForPopup = true
            document.body.classList.add('popup_open_body')
        },
        getCountryFlag: function(countryCode, type) {
            if (countryCode == null) {
                countryCode = ''
            }
            let countryName = countryCode.toLowerCase()
            let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
            if (country && country.length > 0) {
                country = country[0]
                if (type == 'flag') {
                country = `https://567c9b4f06e54c9c9229fe11191a6432.s3.ca-central-1.amazonaws.com/media/shiny/${country.value}.png`
                } else {
                country = country.name
                }
            } else {
                country = ''
            }
            return country;
        },
    }
}
</script>
<style lang="scss" scoped>
.appointment_list_wrp {
    .appointment_list_header_wrp {
        padding-bottom: 25px;

        .back_btn {
            border-radius: 35px;
            padding: 0;
            margin-right: 5px;
            font-size: 16px;
            display: inline-block;
            vertical-align: text-bottom;
            line-height: 32px;
            width: 32px;
            height: 32px;
            outline: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            background: rgba(255, 255, 255, 0.1);
            color: #fff;
        }

        .appointment_list_heading {
            color: #fff;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 700;
            margin: 0;
        }

        .online_appointment_searchbar {
            position: relative;
            display: inline-block;
            vertical-align: middle;

            .fa {
                font-size: 18px;
                cursor: pointer;
                color: #c0c0c0;
                position: absolute;
                top: 8px;
                left: 10px;
            }

            .form-control {
                background: rgba(228, 233, 237, 0.1);
                box-shadow: -5px 5px 10px rgba(82, 106, 133, 0.06);
                font-size: 18px;
                color: #fff;
                font-family: 'MyriadProRegular', sans-serif;
                border: none;
                height: auto;
                width: 280px;
                line-height: 36px;
                padding: 0px 15px 0 40px;
                -webkit-box-shadow: none;
                box-shadow: none;

                &::-moz-placeholder {
                    color: #dfdfdf;
                }

                &::-webkit-input-placeholder {
                    color: #dfdfdf;
                }

                &:focus::-webkit-input-placeholder {
                    font-size: 0px;
                }

                &:focus::-moz-placeholder {
                    font-size: 0px;
                }
            }
        }
    }

    .appointment_list_body_wrp {
        .table {
            margin: 0px;
            letter-spacing: 0.5px;
            color: #ffffff;
            border-collapse: separate;
            border-spacing: 0 3px;

            thead {
                th {
                    border-bottom: 0;
                    font-size: 19px;
                    padding: 10px 8px;
                    font-family: 'MyriadProRegular', sans-serif;
                    text-transform: uppercase;
                    border-top: 0;
                    transition: all .5s ease;
                    -webkit-transition: all .5s ease;
                    -moz-transition: all ease .5s;
                    background: rgba(228, 233, 237, .1);
                    color: #87cefa;
                }

                .empty_cell th {
                    padding: 0px !important;
                    background: none !important;
                }
            }

            tbody {
                td {
                    color: #fff;
                    vertical-align: middle;
                    padding: 10px 8px;
                    font-size: 20px;
                    font-family: 'MyriadProRegular', sans-serif;
                    transition: all .5s ease;
                    -webkit-transition: all .5s ease;
                    -moz-transition: all ease .5s;
                    height: 70px;
                    line-height: 1.3;
                    border-top: 0;
                    background: rgba(228, 233, 237, .1);

                    &.symptoms_item span:last-child i {
                        display: none;
                    }

                    a {
                        color: #fff;
                        text-decoration: none;

                        &:hover {
                            color: #ffd400;
                        }
                    }
                    .country-flag{width:40px;}
                    .member_user_avtaar{
                        img{    
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            margin-right:10px;
                        }
                    }
                }
            }
        }
    }

    .status_btn_wrp {
        .btn {
            padding: 8px 10px;
            line-height: 1;
            outline: none !important;
            box-shadow: none !important;
        }
    }

    .btn-danger {
        &:hover {
            background: #87cefa;
            color: #000 !important;
            border-color: #87cefa;
        }
    }

    .payment_status_text {
        font-size: 16px;
        display: block;
        color: #ffd400;
        margin-top: 4px;
    }

    .payment_inpro_btn {
        background: #ffd400 !important;
        border: none !important;
        color: #000 !important;

        &:hover {
            background: #87cefa !important;
        }
    }

    @media only screen and (max-width:767px) {
        .container-fluid {
            padding: 0px;
        }
    }

    .no_data_in_info_box_wrp {
        text-align: center;
        padding: 25px 0;

        h4 {
            font-family: 'MyriadProRegular', sans-serif;
            font-size: 20px;
            color: #fffeea;
            margin-bottom: 20px;

            @media only screen and (max-width:767px) {
                font-size: 16px;
            }
        }

        .btn-default {
            background: rgba(255, 255, 255, 0.12);
            border: none;
            color: #ffd400;
            font-family: 'MyriadProRegular', 'Lato', sans-serif;
            box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.05), -1px -1px 1px rgba(255, 255, 255, 0.05);
            outline: none !important;

            &:hover {
                background: #87cefa;
                color: #000000 !important;
            }
        }
        @media only screen and (max-width:767px) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
}
    .btn-primary{
        &:hover {
            background: #87cefa !important;
            color: #000000 !important;
        }
    }
.dctr_slctd_cat{
    span{
        background: #261139;
        padding: 1px 12px 2px;
        line-height: 1;
        border-radius: 20px;
        font-size: 14px;
        color: #a560e8;
        text-transform:capitalize;
    }
}
</style>